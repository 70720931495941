<template>
  <div>
    <v-container grid-list-xs>
      <v-tabs
        color="black"
        slider-color="black"
        class="my-3"
        center-active
        hide-slider
      >
        <v-tab
          v-for="(v, i) in tag_list"
          :key="i"
          @click="scroll(`${v.name}`)"
          class="px-2"
          ><v-card
            class="py-3 px-8 rounded-pill"
            color="greener white--text"
            v-if="v.name == first"
          >
            {{ v.name }}
          </v-card>
          <v-card class="py-3 px-8 rounded-pill" v-else>
            {{ v.name }}
          </v-card>
        </v-tab>
      </v-tabs>
      <p class="text-center mt-10" v-if="franchise.custom_message && franchise.custom_message !== ''">
        {{ franchise.custom_message }}
      </p>
      <div v-for="(value, index) in tag_list" :key="index">
        <h2 class="nunito mb-6 font-weight-bold leaf--text">
          {{ value.name }}
        </h2>
        <v-row>
          <v-col
            lg="2"
            sm="3"
            cols="6"
            class="flex flex-wrap"
            v-for="(v, i) in prod_for(value)"
            :key="i"
            :ref="value.name"
          >
            <v-card
              flat
              class="mb-3"
              @click="selectProduct(v)"
              :disabled="v.total_left == 0 || v.out_of_stock ? true : false"
            >
              <v-img
                height="200px"
                max-width="200px"
                class="rounded-lg"
                v-if="v.media_urls.length > 0"
                :src="image(v.media_urls[0])"
              ></v-img>
              <v-img
                v-else
                height="200px"
                max-width="200px"
                class="rounded-lg"
                :src="emptyImage()"
              ></v-img>
              <!-- <v-sheet height="200px" v-else>
                <span class="height text-center grey lighten-3 rounded-lg"
                  ><h3 class="py-16 blue-grey--text text-lighten-3 height">
                    {{ franchise.logo }}
                  </h3></span
                >
              </v-sheet> -->
              <div class="text-center nunito py-3">
                <h3>
                  <b>{{ v.name }}</b>
                </h3>
                <!-- <h4 class="lato red--text">
                  {{ v.tags.length > 0 ? v.tags[0].name : "" }}
                </h4> -->
                <div class="flex justify-center">
                  <h5
                    class="lato font-weight-bold font-italic red--text"
                    v-if="v.total_left == 0 || v.out_of_stock"
                  >
                    Sold Out
                  </h5>
                  <div v-else class="flex justify-center">
                    <h5
                      class="lato font-weight-bold ml-1"
                      v-if="v.price_type == 0"
                    >
                      <div v-if="v.variants.length > 1">
                        <div v-for="(vars, index) in v.variants" :key="index">
                          {{
                            vars.default == true
                              ? "RM" + currency(vars.price)
                              : ""
                          }}
                        </div>
                      </div>
                      <div v-else>RM {{ currency(v.unit_price) }}</div>
                    </h5>
                    <h5
                      class="lato font-weight-bold ml-1"
                      v-else-if="v.price_type == 1"
                    >
                      RM {{ currency(v.unit_price) }}
                    </h5>
                    <h5 class="lato font-weight-bold ml-1" v-else>
                      RM {{ currency(v.unit_price) }}
                    </h5>
                    <h5
                      class="lato font-weight-bold ml-2 grey--text line-through"
                      v-if="v.before_discount"
                    >
                      RM {{ currency(v.before_discount) }}
                    </h5>
                  </div>
                </div>
                <!-- <span class="flex justify-center">
                  <h4
                    class="lato font-weight-bold font-italic"
                    v-if="v.total_left < 20 && v.total_left > 0"
                  >
                    {{ v.total_left }} stocks left
                  </h4>
                  <h4
                    class="lato font-weight-bold font-italic red--text"
                    v-else-if="v.total_left == 0"
                  >
                    Sold Out
                  </h4>
                </span> -->
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <!-- <div v-else>
        <h3 class="blue-grey--text text-center my-16">No items available</h3>
      </div> -->
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "grid",

  data() {
    return {
      first: null,
    };
  },

  computed: {
    ...mapGetters({
      product: "getProduct",
      cart: "getCart",
      franchise: "getFranchise",
    }),

    currency() {
      return require("currency.js");
    },

    tag_list() {
      var tags = {};
      this.product.forEach((prod) => {
        prod.tags.forEach(function (tag) {
          if (!tag.hidden_tag) {
              return (tags[tag.id] = tag); 
          }
        });
      });
      return Object.values(tags).sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
  },

  methods: {
    image(i) {
      if (i.s512) {
        return i.s512;
      } else
        return (
          "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
          i.original
        );
    },
    emptyImage() {
      return (
        "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
        this.franchise.logo
      );
    },

    selectProduct(i) {
      // console.log("i", i);
      this.$store.dispatch("updateSP", i);
      this.track(i.name);
      this.$router.push({
        name: "Page",
        params: { child: this.$route.params.child, page: "product" },
      });
    },

    track(val) {
      this.$gtag.event("view_product", {
        event_category: "view_product",
        event_label: "view_product",
        value: val,
      });
    },

    scroll(refName) {
      this.first = refName;
      var element = this.$refs[refName];

      var top = element[0].offsetTop;
      window.scrollTo(0, top);
    },

    prod_for(tag) {
      var list = this.product.filter((prod) =>
        prod.tags.some((t) => t.id == tag.id && !tag.hidden_product)
      );
      var priority_list = [
        ...list.filter((p) => p.media_urls.length > 0),
        ...list.filter((p) => p.media_urls.length == 0),
      ];
      priority_list = priority_list.sort(function (a, b) {
        var names = { a: a.code || a.name, b: b.code || b.name };
        if (names.a < names.b) {
          return -1;
        }
        if (names.a > names.b) {
          return 1;
        }
        return 0;
      });
      return priority_list;
    },
  },

  mounted() {
    // console.log(this.product)
   //  console.log("grid");
    // this.scroll(this.tag_list[0].name);
    if (this.tag_list.length > 0) {
      this.first = this.tag_list[0].name;
    }
  },
};
</script>
<style>
.elipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.height {
  height: 100%;
}
.greener {
  background-color: #279474 !important;
}
.line-through {
  text-decoration: line-through red;
}
.v-slide-group__prev {
  display: none !important;
  visibility: hidden;
}
</style>
